<template>
    <div class="app_container">
        <div class="week_bg" style="background-image: url(img/weekParty/top_bg.png);">
            <div class="st_rule" style="background-image: url(img/weekParty/rule_button.png);" @click="goRule"></div>
            <!-- <div class="st_share_button" style="background-image: url(img/weekLuckyMan/share_button.png);" @click="goShare">
        </div> -->
            <!-- 上半部分 -->

            <!-- 抽奖转盘 -->
            <div class="main_box">
                <div class="active_title_2" style="background-image: url(img/weekParty/active_title.png);"></div>
                <div class="active_tip" style="background-image: url(img/weekParty/active_tip.png);"></div>
                <div class="active_title_2" style="background-image: url(img/weekParty/active_title_4.png);"></div>

                <!-- <div class="go_back_button" style="background-image: url(img/go_back.png);" @click="goBack"></div> -->
                <div class="total_count">
                    <span>剩余次数：{{ draw_num }}</span>
                </div>
                <div class="lottery_box">

                    <div class="jump_animation" @click="jumpAnimation">
                        <div v-if="checked == true" style="background-image: url(img/weekParty/checked.png);"></div>
                        <div v-if="checked == false" style="background-image: url(img/weekParty/unchecked.png);"></div>
                        <span style=" color: #ffffff;margin-left:5px">跳过动画</span>
                    </div>
                    <div class="total_num">{{'本周累计次数：'+total_num}}</div>
                    <div class="lottery_item_box">
                        <div class="lottery_circle"
                            :style="{ transform: rotate_deg, transition: rotate_transition, backgroundImage: 'url(img/weekParty/lottery_circle.png)' }">
                        </div>
                        <div class="foot">
                            <div class="foot_item" style="background-image: url(img/weekParty/foot_left.png);"></div>
                            <div class="foot_item" style="background-image: url(img/weekParty/foot_right.png);"></div>
                        </div>
                        <div class="lottery_click" @click="start(1)"
                            style="background-image: url(img/weekParty/lottery_click.png);"></div>
                    </div>

                </div>
                <div class="lottery_btn_box">
                    <div class="once_btn" style="background-image: url(img/weekParty/once.png);" @click="start(1)"></div>
                    <div class="tenth_btn" style="background-image: url(img/weekParty/tenth.png);" @click="start(10)"></div>
                </div>
                <div class="rank_record">
                    <div class="rank" style="background-image: url(img/weekParty/rank.png);" @click="goLucky"></div>
                    <div class="record" style="background-image: url(img/weekParty/record.png);" @click="goRecord"></div>
                </div>
            </div>

            <div class="bottom_bg" style="background-image: url(img/weekParty/bottom_text.png);"></div>
        </div>

        <!-- 分享弹框 -->
        <!-- <div class="mask" v-if="showMask">
        <div class="gift_box_share" style="background-image: url(img/weekLuckyMan/share_bg.png);">
          <div class="btn-container">
            <div class="wechat" style="background-image: url(img/wechat_bg.png);" @click="shareToWechat"></div>
            <div class="firend_circle" style="background-image: url(img/firend_circle.png);" @click="shareToTimeline">
            </div>
          </div>
          <div class="ok_btn" style="background-image: url(img/weekLuckyMan/ok.png);" @click="closeMask"></div>
        </div>
      </div> -->
        <!-- 奖品弹框 -->
        <div class="mask" v-if="showGiftMask">
            <div v-if="onceCount == 1" class="gift_box" style="background-image: url(img/weekParty/gift_dialog_bg.png);">
                <div style="width:100%;height:100%;position: relative;overflow: hidden;">
                    <div class="gift_item_box" style="background-image: url(img/weekParty/gift_item_bg.png);">
                        <img class="gift_pic" :src="gift.gift_url" alt="">
                    </div>
                    <div class="diamond_gift_name">
                        <div class="gift_item_name">{{ gift.gift_name }}</div>
                        <div class="diamond_and_price" v-if="gift.diamonds > 0">
                            <img class="diamond" src="img/weekParty/diamond.png" alt="">
                            <span class="price">{{ gift.diamonds }}</span>
                        </div>
                    </div>
                    <div class="ok_btn" style="background-image: url(img/weekParty/ok.png);" @click="closeGiftMask"></div>
                </div>

            </div>
            <div v-if="onceCount == 10" class="gift_box_2" style="background-image: url(img/weekParty/gift_dialog_bg.png);">
                <div class="gift_container">
                    <div class="gift_container_item" v-for="(item, index) in giftArr" :key="index">
                        <div class="gift_item_box_2" style="background-image: url(img/weekParty/gift_item_bg.png);">
                            <img class="gift_pic_2" :src="item.gift_url" alt="">
                            <div class="number">{{ 'X' + item.num }}</div>
                        </div>
                        <div class="diamond_gift_name_2">
                            <div class="gift_item_name_2">{{ item.gift_name }}</div>
                            <div class="diamond_and_price_2" v-if="item.diamonds > 0">
                                <img class="diamond_2" src="img/weekParty/diamond.png" alt="">
                                <span class="price_2">{{ item.diamonds }}</span>
                            </div>
                        </div>

                    </div>


                </div>
                <div class="ok_btn" style="background-image: url(img/weekParty/ok.png);" @click="closeGiftMask"></div>
            </div>
        </div>
        <div class="mask" v-if="showTipMask">
            <div class="tip_bg" style="background-image: url(img/weekParty/tip_bg.png);overflow: hidden;">
                <div class="tip_title"></div>
                <div class="carrot_tip">{{ tip }}</div>
                <div class="i_know" style="background-image: url(img/weekParty/ok.png);" @click="closeTipMask"></div>
            </div>
        </div>
    </div>
</template>
    
<script>
import "@/assets/css/weekLuckyMan.css"
// import vueSeamlessScroll from 'vue-seamless-scroll'
import { weekPartyIndex, weekPartyDraw } from "@/api/ranking";

export default {
    name: 'weekParty',
    // components: {
    //   vueSeamlessScroll
    // },
    data() {
        return {
            list: [],
            noData: true,
            curTitle: 1,
            checked: false,
            showMask: false,
            showGiftMask: false,
            onceCount: 0,
            tt: null,
            showTipMask: false,
            gift: '',
            tip: '',
            giftArr: [],
            isAllowClick: true, //是否能够点击
            rotate_deg: 0, //指针旋转的角度
            rotate_transition: " ", //初始化选中的过度属性控制
            listData: [],
            giftList: [],
            param: {
                url: '',
                logo: '',
                desc: '',
                title: '',
                content: ''
            },
            draw_num: 0,
            total_num: 0
        }
    },
    created() {
        // console.log(this.$route.query.access_token)   
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getData()
    },
    methods: {
        start(count) {
            if (this.draw_num == 0 || this.draw_num < count) {
                this.tip = '您的抽奖次数不足，请先获得抽奖次数吧~'
                this.showTipMask = true
                return;
            }
            if (!this.isAllowClick) {
                return
            }

            this.isAllowClick = false
            this.onceCount = count
            this.goLottery(count)
        },
        jumpAnimation() {
            this.checked = !this.checked
            if (this.checked) {
                console.log(this.rotate_transition)
                if (this.rotate_transition == 'transform 3s ease-in-out') {
                    this.showGiftMask = true
                    this.rotate_transition = "";
                } else {
                    this.showGiftMask = false
                }
                clearTimeout(this.tt)
            }
        },
        rotating(angle) {
            console.log(angle)
            if (!this.isAllowClick || this.checked == true) return;
            this.isAllowClick = false;
            this.rotate_transition = "transform 3s ease-in-out";
            var rand_circle = 5; //默认多旋转5圈
            var randomDeg = angle;
            var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
            this.rotate_deg = "rotate(" + deg + "deg)";
            var that = this
            that.tt = setTimeout(function () {
                that.showGiftMask = true
            }, 3300)
        },
        closeGiftMask() {
            this.showGiftMask = false
            this.reset()
            this.getData()
        },
        reset() {
            this.isAllowClick = true;
            this.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
            this.rotate_transition = "";
            clearTimeout(this.tt)
        },
        getData() {
            weekPartyIndex({}).then(res => {
                console.log(res)
                this.draw_num = res.data.current_num
                this.total_num = res.data.total_num
            },
                error => {
                    // this.$toast.fail(error.msg)
                }).catch((error) => {

                    // this.$toast.fail(error.msg)
                })
        },
        goRule() {

            this.$router.push({ path: '/partyActiveRule' })
        },
        goRecord() {
            this.$router.push({ path: '/partyLotteryRecord' })
        },
        goLucky() {
            this.$router.push({ path: '/partyLuckyPeople' })
        },
        goLottery(count) {

            let draw_num = count
            // this.isAllowClick = false;
            // this.rotating(45)
            weekPartyDraw({ draw_num }).then(response => {
                {
                    let angle = response.data.data[0].angle
                    console.log(angle)
                    if (draw_num == 1) {
                        this.gift = response.data.data[0]
                    } else {
                        this.giftArr = response.data.data
                    }
                    this.draw_num = response.data.draw_num
                    this.reset()
                    if (this.checked) {
                        this.showGiftMask = true
                    } else {
                        this.rotating(angle)
                    }
                }

            },
                error => {
                    // this.noData = true
                    this.$toast.fail(error.data.msg)
                    this.isAllowClick = true
                }).catch((error) => {
                    // this.noData = true
                    // this.$toast.fail(error.msg)
                })
        },
        goShare() {
            this.showMask = true
            // share({}).then(res => {
            //   console.log(res)
            // })


        },
        closeMask() {
            this.showMask = false

        },
        closeTipMask() {
            this.showTipMask = false
            this.reset()
        },
        shareToWechat() {
            let param = this.param
            this.$bridge.callhandler('shareToWechat', param, (data) => {
                // 处理返回数据
            })
        },
        shareToTimeline() {
            let param = this.param
            this.$bridge.callhandler('shareToTimeLine', param, (data) => {
                // 处理返回数据
            })
        }
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
    